import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ILoginResponse } from 'src/app/auth/login/login.model';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  userDetails : ILoginResponse
  constructor(private router : Router , private sharedData : SharedDataService) { }

  ngOnInit() {
    this.sharedData.userDetails.subscribe((resdata: ILoginResponse) =>{
      if(resdata){
       this.userDetails = resdata;
      }
      })

  }


  onLogOut(){
    this.router.navigate(['/auth']);
    localStorage.clear();
  }
}
