import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

   //baseUrl : string = 'http://localhost:23072/api/';
   
  baseUrl : string = 'https://automation.edinemenu.com/webapi/api/';
  // baseUrl1 : string = 'https://test.edinemenu.com/webapi/api/';


  constructor(private http : HttpClient) { 
    
  }


  getApi(url : string){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    return this.http.get(this.baseUrl + url, httpOptions);
  }


  optionApi(url : string){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    return this.http.options(this.baseUrl + url, httpOptions);
  }



  postFormDataApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.baseUrl + url, requestObj, httpOptions)
  }
  postApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.baseUrl + url, requestObj, httpOptions)
  }
  // postTableApi(url : string , requestObj : any){
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Authorization: "bearer " + localStorage.getItem("userToken"),
  //     }),
  //   };
  //   return this.http
  //     .post(this.baseUrl1 + url, requestObj, httpOptions)
  // }
  putApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.baseUrl + url, requestObj, httpOptions)
  }
  putFormDataApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.baseUrl + url, requestObj, httpOptions)
  }


  deleteApi(url : string){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .delete(this.baseUrl + url, httpOptions)
  }

  
}
