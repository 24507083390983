  export class apiUrl {


    // region login
    public static get Login(): string { return "Authenticate"; }
    public static get CheckToken(): string { return "Authenticate/CheckToken"; }

    // end
    // region organization
    public static get OrganizationGetAll(): string { return "Organizations/GetOrganizations/"; }
    public static get OrganizationGetById(): string { return "Organizations"; }
    public static get OrganizationSave(): string { return "Organizations"; }
    public static get OrganizationDelete(): string { return "Organizations"; }
    public static get OrganizationGetAllForDropdown(): string { return "Organizations/GetdlOrganizations/"; }

    // end

    
    // region organization
    public static get CompanyGetAll(): string { return "Companies"; }
    public static get CompanyGetById(): string { return "Companies"; }
    public static get CompanyGetAllForDropdown(): string { return "Companies/GetdlCompanies"; }
    public static get CompanySave(): string { return "Companies"; }
    public static get CompanyDelete(): string { return "Companies"; }
    // end

    // region order
    public static get OrderGetAll(): string { return "Orders?"; }
    public static get OrderGetById(): string { return "Orders"; }
    public static get OrderGetAllForDropdown(): string { return "Orders/GetdlOrders"; }
    public static get OrderSave(): string { return "Orders"; }
    public static get OrderDelete(): string { return "Orders"; }
    public static get OrderOnline() : string { return "OrderDriverMappings/GetOnlineOrders?"}
    public static get Orderlive(): string { return "OrderDriverMapping/GetliveOrder"}
    // end

    // region store
    public static get StoreGetAll(): string { return "Stores?"; }
    public static get StoreGetById(): string { return "Stores"; }
    public static get StoreGetAllForDropdown(): string { return "Stores/GetdlCompanies"; }
    public static get StoreSave(): string { return "Stores"; }
    public static get StoreDelete(): string { return "Stores"; }
    // end
    // region branch
    public static get BranchGetAll(): string { return "Branches?"; }
    public static get BranchGetById(): string { return "Stores"; }
    public static get BranchGetAllForDropdown(): string { return "Branches/GetdlBranch/"; }
    public static get BranchSave(): string { return "Stores"; }
    public static get BranchDelete(): string { return "Stores"; }
    public static get StoreGetAllForBranchDropdown(): string { return "Stores/GetdlStore/"; }

 
    // end

    // region Cameras
    public static get CameraGetAll(): string { return "Cameras?"; }
    public static get CameraGetById(): string { return "Cameras"; }
    public static get CameraSave(): string { return "Cameras"; }
    public static get CameraDelete(): string { return "Cameras"; }
    public static get CameraGetAllForDropDown(): string { return "Cameras/GetdlCameras/"; }
    public static get CameraGetAllForDropDownByOrganization(): string { return "Cameras/GetdlOrganizationCameras/"; }


    public static get CameraEventMappingInsert():string { return "Cameras/CameraEventMappingInsert";}
      public static get CameraEventMappingGet():string { return "Cameras/GetCameraEventMapping/";}
      public static get CameraSchedulesGet():string { return "Cameras/GetCameraSchedules/";}
      public static get CameraSchedulesInsert():string { return "Cameras/InsertCameraSchedule";}

    // end

    // region Employees
    public static get EmployeeGetAll(): string { return "Employees?"; }
    public static get EmployeeGetAllWithStore(): string { return "Employees/GetEmployeesData?"; }
    public static get EmployeeeGetByBranchId(): string { return "Employess/GetdlEmployeesByBranch/"}
    public static get EmployeeGetByStore(): string { return "Employees/GetDriver"}
    public static get EmployeeGetById(): string { return "Employees"; }
    public static get EmployeeSave(): string { return "Employees"; }
    public static get EmployeeDelete(): string { return "Employees"; }
    public static get EmployeeGetAllForDropdown(): string { return "Employees/GetdlEmployees"; }
    // end


   //region Sensor
   public static get SensorGetAll(): string { return "Sensors?"; }
   public static get SensiableSensorGetAll(): string { return "Sensors/EnvironmentSensors"; }


   //end



    // region Packages
    public static get PackagesGetAll(): string { return "Packages"; }
    public static get PackagesGetById(): string { return "Packages"; }
    public static get PackagesSave(): string { return "Packages"; }
    public static get PackagesDelete(): string { return "Packages"; }
    public static get PackagesGetAllForDropdown(): string { return "Packages/GetdlPackages"; }

    // end


    // region Plans
    public static get PlansGetAll(): string { return "Plans"; }
    public static get PlansGetById(): string { return "Plans"; }
    public static get PlansSave(): string { return "Plans"; }
    public static get PlansDelete(): string { return "Plans"; }
    public static get PlansGetAllForDropdown(): string { return "Plans/GetdlPlans"; }

    // end
    // region Subscriptions
    public static get SubscriptionsGetAll(): string { return "Subscriptions"; }
    public static get SubscriptionsGetById(): string { return "Subscriptions"; }
    public static get SubscriptionsSave(): string { return "Subscriptions"; }
    public static get SubscriptionsDelete(): string { return "Subscriptions"; }
    public static get SubscriptionsGetAllForDropdown(): string { return "Subscriptions/GetdlSubscriptions"; }

    // end


    // region Roles
    public static get RoleGetAll(): string { return "Roles"; }
    public static get RoleGetById(): string { return "Roles"; }
    public static get RoleSave(): string { return "Roles"; }
    public static get RoleDelete(): string { return "Roles"; }
    public static get RoleGetAllForDropdown(): string { return "Roles/GetdlRoles"; }

    // end
    
    // region Rooms
    public static get RoomGetAll(): string { return "Rooms?"; }
    public static get RoomGetById(): string { return "Rooms"; }
    public static get RoomSave(): string { return "Rooms"; }
    public static get RoomDelete(): string { return "Rooms"; }
    public static get RoomGetAllForDropdown(): string { return "Rooms/GetdlRoom/"; }
    public static get RoomTypesGetAll(): string { return "Rooms/GetdlRoomTypes"; }

    // end
    
    // region Users
    public static get UserGetAll(): string { return "Users?"; }
    public static get UserGetById(): string { return "Users"; }
    public static get UserSave(): string { return "Users"; }
    public static get UserDelete(): string { return "Users"; }
    public static get UserGetAllForDropdown(): string { return "Users/GetdlUsers/"; }

    // end 
    
    // region Floors
    public static get FloorGetAll(): string { return "Floors?"; }
    public static get FloorGetById(): string { return "Floors"; }
    public static get FloorGetAllForDropdown(): string { return "Floors/GetdlFloor/"; }
    public static get FloorsSave(): string { return "Floors"; }
    public static get FloorsDelete(): string { return "Floors"; }

    public static get GetCameraPositionData(): string { return "Floors/GetCameraPositionData/"; }
    public static get PostCameraPositionData(): string { return "Floors/PostCameraPositionData"; }

    // end


    // region Floors
    public static get AssetDocumentGetAll(): string { return "AssetDocuments/GetAssetDocuments"; }
    public static get AssetDocumentGetById(): string { return "AssetDocuments"; }
    public static get AssetTypesGetAllForDropdown(): string { return "AssetDocuments/GetdlAssetTypes"; }
    public static get AssetDocumentsSave(): string { return "AssetDocuments"; }
    public static get AssetDocumentsUpdate(): string { return "AssetDocuments"; }
    public static get AssetDocumentsDelete(): string { return "AssetDocuments"; }

    //end


      // region EventGroup
      public static get EventGroupGetAll(): string { return "EventGroups"; }
      public static get EventGroupGetById(): string { return "EventGroups"; }
      public static get EventGroupGetAllForDropdown(): string { return "EventGroups/GetdlEventGroups"; }
      public static get EventGroupSave(): string { return "EventGroups"; }
      public static get EventGroupDelete(): string { return "EventGroups"; }
      public static get EventGroupMapping():string { return "EventGroups/EventGroupMappingInsert";}
      public static get EventGroupMappinglist():string { return "EventGroups/GetEventGroupMapping/";}
      

      // end


      // region EventType
      public static get EventTypeGetAll(): string { return "EventTypes"; }
      public static get EventTypeGetById(): string { return "EventTypes"; }
      public static get EventTypeGetAllForDropdown(): string { return "EventTypes/GetdlEventTypes"; }
      public static get EventTypeSave(): string { return "EventTypes"; }
      public static get EventTypeDelete(): string { return "EventTypes"; }
      // end





      // region Dashboard
      public static get DashboardConnectedDatas(): string { return "Dashboard/GetConnectedData?"; }
      public static get DashboardOccupancyConnectedDatas(): string { return "OccupancyDashboard/GetOccupancyConnectedData"; }
      public static get DashboardEnvironmentDatas():string { return "Sensors/EnvironmentdataSensors"}
      public static get DashbaordConnectedDataByStore():string { return "Dashboard/GetConnectedDataByStore"}
      public static get DashboardCo2LiveData():string { return "Sensors/EnvironmentCo2Sensors"}
      public static get DashboardHumidityLiveData():string { return "Sensors/EnvironmentHumiditySensors"}
      public static get DashboardPressureLiveData():string { return "Sensors/EnvironmentPressureSensors"}
      public static get DashboardTempLiveData():string { return "Sensors/EnvironmentTempSensors"}
      public static get DashboardIaqLiveData():string { return "Sensors/EnvironmentIaqSensors"}
      public static get DashboardVocLiveData():string { return "Sensors/EnvironmentVocSensors"}
      public static get wasteSensorConnectedData(): string { return "WasteSensors/WasteSensorsData"}
  
      public static get DashboardSeatOccupancyData():string { return "OccupancyDashboard/GetSeatReportbyFilter"}
      public static get DashboardMostOccupiedData():string { return "OccupancyDashboard/GetSeatOccupancybyFilter"}

      // end
      public static get GetActiveOrders(): string { return "Orders/GetActiveOrder?"; }



      // region ViolationDashboard
      public static get GetTokenByStatus (): string { return "Tokens/GetTokensbyStatus" }
      public static get GetAssignedTokens (): string { return "Tokens/GetAssignedTokens/0" }

      public static get TokenEmployeesMapping (): string { return "Tokens/TokenEmployeesMapping" }
      public static get GetTokenNotes (): string { return "TokenNotes/GetTokenNotes/" }
      public static get GetToken (): string { return "Tokens/" }
      public static get GetTokenStatusLog (): string { return "Tokens/GetTokenStatusLog/" }


      public static get GetDashboardEventData (): string { return "Dashboard/GetEventData" } 
      public static get GetEventTypeCountReportbyFilter (): string { return "Dashboard/GetEventTypeCountReportbyFilter" } 

      public static get GetEmployeeCountReportbyFilter (): string { return "Dashboard/GetEmployeeReportbyFilter" } 
      public static get GetTemperatureReportData(): string { return "SensorDashboard/GetTemperatureLineChart"}
      public static get GetHumidityReportData(): string { return "SensorDashboard/GetHumidityLineChart"}
      public static get GetPressureReportData(): string { return "SensorDashboard/GetPressureLineChart"}
      public static get GetVocReportData(): string { return "SensorDashboard/GetVocLineChart"}
      public static get GetIaqReportData(): string { return "SensorDashboard/GetIaqLineChart"}
      public static get GetCo2ReportData(): string { return "SensorDashboard/GetCo2LineChart"}
      public static get GetCityCountData(): string { return "Dashboard/GetLocationPieChartReport"}
      public static get GetOrderCountData(): string { return "Dashboard/GetOrderCreatedOnbyFilter"}
     
      public static get GetTokenStatusCountReport (): string { return "Dashboard/GetTokenStatusCountReport" }
      public static get GetUserTokenStatusCountReport (): string { return "Dashboard/GetUserTokenStatusCountReport" }


      public static get GetDashboardTokenStatusData (): string { return "Dashboard/GetTokenStatusData/" }

      public static get GetTokenStatus (): string { return "Tokens/GetTokenStatus" }

      public static get UpdateTokenStatus (): string { return "Tokens/UpdateTokenStatus/" }


      public static get GetEventMonthlyChart (): string { return "Dashboard/GetEventMonthlyChart" }
      public static get GetMonthlyChartbyFilter (): string { return "Dashboard/GetMonthlyChartbyFilter" }

      public static get GetOrderReport ():string { return "Reports/GetOrderReport"}
      public static get GetAssetReport ():string { return "Reports/GetAssetReport"}
      public static get GetSensorReport ():string { return "Reports/GetSensorReport"}
      public static get GetEmployeeReport (): string { return "Dashboard/GetEmployeeReport" }

      public static get GetEmployeeViolationChart (): string { return "Dashboard/GetEmployeeViolationChart" }

      public static get GetCameraViolationPieChartReport (): string { return "Dashboard/GetCameraViolationPieChartReport" }
      public static get GetFloorViolationPieChartReport (): string { return "Dashboard/GetFloorViolationPieChartReport" }



      public static get GetTokenReport (): string { return "Dashboard/GetTokenReport" }


      public static get GetMonthlyChartbyEventType (): string { return "Dashboard/GetMonthlyChartbyEventType/" }







      public static get GetViolatedEmployees (): string { return "TokenNotes/GetViolatedEmployees/" }
      public static get GetTokenAssignedUsers (): string { return "Tokens/GetAssignedUsers/" }

      public static get ViolatedEmployees (): string { return "TokenNotes/ViolatedEmployees" }
      public static get TokenNotes (): string { return "TokenNotes" }




      // end



      // region Maintenance Rule
    public static get RuleGetAll(): string { return "Rules"; }
    public static get RuleGetById(): string { return "Rules"; }
    public static get RuleSave(): string { return "Rules"; }
    public static get RuleDelete(): string { return "Rules"; }



    public static get RuleGetAllForDropdown(): string { return "Rules/GetdlRules"; }
    public static get GetCameraRuleMapping(): string { return "Rules/GetCameraRuleMapping/"; }
    public static get InsertCameraRuleMapping(): string { return "Rules/CameraRuleMappingInsert"; }


    // end
      // region Maintenance MAintencance
    public static get MaintenanceGetAll(): string { return "Maintenances"; }
    public static get MaintenanceGetById(): string { return "Maintenances"; }
    public static get MaintenanceSave(): string { return "Maintenances"; }
    public static get MaintenanceDelete(): string { return "Maintenances"; }


    // end


    //driver info
    public static get DriversGetAll(): string { return "Employees"; }
    public static get DriversGetById(): string { return "Employees"; }
    public static get DriversSave(): string { return "Employees"; }
    public static get DriversDelete(): string { return "Employees"; }
    public static get DriversGetAllForDropdown(): string { return "Employees/GetdlEmployees"; } 
    public static get GeotabDrivers(): string { return "Employees/Getgeotabdrivers"; } 
    public static get EmployeeMappingGetAll(): string { return "Employees/GetEmployeeStoreMapping?";}
    public static get EmployeeInsert(): string { return "Employees/EmployeeStoreMappingInsert";}


    //Vehicle info

    public static get VehiclesGetAll(): string { return "Assets?" }
    public static get VehiclesGetAllStore(): string { return "Assets/GetAssetData?" }
    public static get VehiclesGetById(): string { return "Employees"; }
    public static get VehiclesSave(): string { return "Employees"; }
    public static get VehiclesDelete(): string { return "Employees"; }
    public static get VehicleGetByStoreId() : string { return"Assets/GetdlStore/"}
    public static get VehiclesGetAllForDropdown(): string { return "Assets/GetdlStore"; }
    public static get VehicleInsertData(): string { return "Assets/StoreMappingInsert"; }
   // public static get VehicleInsertData() :string { "Assets/StoreMappingInsert";}
    public static get VehicleMappingGetAll(): string { return "Assets/GetAssetStoreMapping?";}
    
    //location
    public static get LocationsGetAll(): string { return "Dashboard/geotablocationsById";}
    //public static get LocationsGetAll(): string { return "Locations";}
    public static get LocationsGetById(): string { return "Dashboard/locationsbyId";}
    public static get AvailableVehiclebyBranch(): string { return "Assets/GetdlAssetsById"; }
    public static get GeotabDevices(): string { return "Assets/Geotabdevices" }


  }



  export class apiUrlExternal {
    public static get EventTypeGet(): string { return "https://ec2-3-111-171-19.ap-south-1.compute.amazonaws.com/api/EventList/EventList"; }
    public static get CameraGet(): string { return "https://ec2-3-111-171-19.ap-south-1.compute.amazonaws.com/api/CameraList/CameraList"; }


  }
