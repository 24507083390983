import { Routes } from '@angular/router';


export const content: Routes = [
  // {
  //   path: 'auth',
  //   loadChildren: () => import('../../auth/auth.module').then(m => m.AuthModule)
  // },
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'layout-builder',
    loadChildren: () => import('../../views/layout-builder/layout-builder.module').then(m => m.LayoutBuilderModule)
  },
  {
    path: 'masters',
    loadChildren: () => import('../../views/masters/masters.module').then(m => m.MastersModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../views/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../views/dashboard/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'violation-dashboard',
    loadChildren: () => import('../../views/dashboard/violation-dashboard/violation-dashboard.module').then(m => m.ViolationDashboardModule)
  },
  {
    path: 'sensor-dashboard',
    loadChildren: () => import('../../views/dashboard/sensor-dashboard/sensor-dashboard.module').then(m => m.SensorDashboardModule)
  },
  {
    path: 'occupancy-dashboard',
    loadChildren: () => import('../../views/dashboard/occupancy-dashboard/occupancy-dashboard.module').then(m => m.OccupancyDashboardModule)
  },
  {
    path: 'wastesensor-dashboard',
    loadChildren: () => import('../../views/dashboard/wastesensor-dashboard/wastesensor-dashboard.module').then(m => m.WastesensorDashboardModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('../../views/maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },  
  {
    path: 'subscription',
    loadChildren: () => import('../../views/subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  {
    path: 'floor-layout',
    loadChildren: () => import('../../views/floor-layout/floor-layout.module').then(m => m.FloorLayoutModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../views/settings/settings-routing.module').then(m => m.SettingsRoutingModule)
  },
  {
    path: 'assets',
   // loadChildren: () => import('../../views/assets/assets-routing.module').then(m => m.AssetsRoutingModule)
    loadChildren: () => import('../../views/masters/assets/assets-routing.module').then(m => m.AssetsRoutingModule)
  },
  // {
  //   path: 'organisation',
  //   loadChildren: () => import('../../views/organisation/organisation-routing.module').then(m => m.OrganisationRoutingModule)
  // }
  
];
